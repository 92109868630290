<template>
    <div class="points">
      <div class="points__content" v-loading="loading">
        <div class="points__title">
          {{$t('points.title')}}
        </div>
        <div class="points__flex-wrapper">
          <div>
            <div class="points__available">
            {{ $t('points.available') }}, {{locale === 'USA' ? 'USD' : 'RUB'}}
          </div>
          <div class="points__available-amount">
            {{ numberWithSpacesFixed(userData.agent.balance, 2) }} {{locale === 'USA' ? '$' : '₽'}}
          </div>
          </div>
        <div class="points__steps">
          <div class="points__step">
            <div class="points__step-title">
              1. Куда вывести
            </div>
            <div class="points__step-content">
              <div class="points__options">
                <label
                    class="points__options-item"
                    :class="{'points__options-item--active' : selectedPayType === 'account'}"
                >
                  <input
                      type="radio"
                      v-model="selectedPayType"
                      :value="'account'"
                  >
                  <img
                      class="points__options-icon"
                      :src="getPic(['icon-wallet.svg', 'icon-wallet-red.svg'])"
                      alt="main balance"
                  >
                  <span class="points__options-info">
                    <span class="points__options-title">
                      Вывод на счет
                    </span>
                  </span>
                </label>
                <label
                    class="points__options-item"
                    :class="{'points__options-item--active' : selectedPayType === 'business_account'}"
                >
                  <input
                      type="radio"
                      v-model="selectedPayType"
                      :value="'business_account'"
                  >
                  <img
                      class="points__options-icon"
                      :src="getPic(['icon-ba.svg', 'icon-ba.svg'])"
                      alt="main balance"
                  >
                  <span class="points__options-info">
                    <span class="points__options-title">
                      Вывод на свой бизнес-аккаунт
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="points__step">
            <div class="points__step-title">
              2. {{selectedPayType === 'account' ? 'Укажите куда вывести деньги' : 'На какой бизнес-аккаунт вывести'}}
            </div>
            <div class="points__step-content">
              <div v-if="selectedPayType === 'account'" class="dialog-add-account__form-row">
                <div class="dialog-add-account__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input"
                      :class="[{ 'form-input--full': form.bank_name }, { 'form-input--error': errors.bank_name || (serverErrors && serverErrors.bank_name) }]">
                      <input v-model="form.bank_name" type="text">
                      <span class="form-input__label">{{ $t('business_account_data.bank_name') }}</span>
                      <div v-if="errors.bank_name" class="form-input__error" v-text="errors.bank_name" />
                      <div v-if="serverErrors && serverErrors.bank_name" class="form-input__error">
                        <p v-for="(err, index) in serverErrors.bank_name" :key="index">
                          {{ err }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dialog-add-account__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input"
                      :class="[{ 'form-input--full': form.bic }, { 'form-input--error': errors.bic || (serverErrors && serverErrors.bic) }]">
                      <input v-model="form.bic" type="text">
                      <span class="form-input__label">{{ $t('business_account_data.bic') }}</span>
                      <div v-if="errors.bic" class="form-input__error" v-text="errors.bic" />
                      <div v-if="serverErrors && serverErrors.bic" class="form-input__error">
                        <p v-for="(err, index) in serverErrors.bic" :key="index">
                          {{ err }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="dialog-add-account__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input"
                      :class="[{ 'form-input--full': form.iban }, { 'form-input--error': errors.iban || (serverErrors && serverErrors.iban) }]">
                      <input v-model="form.iban" type="text">
                      <span class="form-input__label">{{ $t('business_account_data.account') }}</span>
                      <div v-if="errors.iban" class="form-input__error" v-text="errors.iban" />
                      <div v-if="serverErrors && serverErrors.iban" class="form-input__error">
                        <p v-for="(err, index) in serverErrors.iban" :key="index">
                          {{ err }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="dialog-add-account__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input"
                      :class="[{ 'form-input--full': form.bank_address }, { 'form-input--error': errors.bank_address || (serverErrors && serverErrors.bank_address) }]">
                      <input v-model="form.bank_address" type="text">
                      <span class="form-input__label">{{ $t('business_account_data.bank_address') }}</span>
                      <div v-if="errors.bank_address" class="form-input__error" v-text="errors.bank_address" />
                      <div v-if="serverErrors && serverErrors.bank_address" class="form-input__error">
                        <p v-for="(err, index) in serverErrors.bank_address" :key="index">
                          {{ err }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedPayType === 'business_account'" class="dialog-add-account__form-row">
                <div class="dialog-add-account__form-col selector-section">
                  <div class="dialog-add-promo-section__selector">
                    <div class="dialog-add-promo-section__selector-wrapper">
                      <div class="selector multi-search-selector">
                        <!-- <div class="selected-accounts">
                          <div v-for="item in selectedAccounts" :key="item.id" class="selected-accounts__item selected-accounts__item--admin">
                            <div class="selected-accounts__item-name" v-html="item.name && item.name.length > 0 ? item.name: item.email" />
                            <div class="selected-accounts__item-dell" @click="dellAcc(item.id)">
                              <img src="@/assets/icons/icon-vk-acc-dell.svg">
                            </div>
                          </div>
                        </div> -->
                        <div class="selector__content" @click="showOptions = !showOptions">
                          <div class="selector__title">
                            {{ $t('promo_data.business_accounts') }}
                            <span v-if="selectedAccounts && selectedAccounts[0]">
                              {{ `${selectedAccounts[0].name} ${selectedAccounts[0].lastName}` }}
                            </span>
                          </div>
                          <div class="selector__arrow" :class="{'selector__arrow--open' : showOptions}">
                            <img src="@/assets/icons/icon-arrow.svg" alt="arrow">
                          </div>
                        </div>
                        <div v-if="showOptions" class="selector__options">
                          <div
                              class="form-input selector__options-search"
                              :class="
                            [
                              {'form-input--full' : search}
                            ]"
                          >
                            <input v-model="search" type="text" required>
                            <span class="form-input__label">
                              {{ $t('search_props.placeholder') }}
                            </span>
                          </div>
                          <div
                              v-for="account in filteredAccounts"
                              :key="account.id"
                              class="selector__options-item"
                              :class="{'selector__options-item--active': checkStatus(account.id)}"
                              @click="chooseAccount(account.name, account.id, account.email, account.lastName)"
                          >
                            <template>
                              <div v-if="account.isLegal">
                                {{ account.legalName }}
                              </div>
                              <div v-else>
                                {{ account.name }} {{ account.lastName }}
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="errors.business_account" class="form-input__error" v-text="errors.business_account" />
                    <div v-if="serverErrors && serverErrors.business_account" class="form-input__error">
                      <p v-for="(err, index) in serverErrors.business_account" :key="index">
                        {{ err }}
                      </p>
                    </div>
              </div>
            </div>
          </div>
          <div class="points__step">
            <div class="points__step-title">
              3. Укажите сумму вывода
            </div>
            <span class="note">Минимальная сумма вывода 5000 ₽</span>
            <div class="points__step-content">
              <div class="dialog-add-account__form-row">
                <div class="dialog-add-account__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input"
                      :class="[{ 'form-input--full': form.sum }, { 'form-input--error': errors.sum || (serverErrors && serverErrors.sum) }]">
                      <input v-model="form.sum" type="text">
                      <span class="form-input__label">Сумма</span>
                      <div v-if="errors.sum" class="form-input__error" v-text="errors.sum" />
                      <div v-if="serverErrors && serverErrors.sum" class="form-input__error">
                        <p v-for="(err, index) in serverErrors.sum" :key="index">
                          {{ err }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div v-if="serverErrors" class="points__errors">
        <div class="error">
            {{ serverErrors }}
        </div>
      </div>
      <div class="points__final">
        <div class="points__final-content">  

          <div class="points__final-title">
            {{$t('points.total_amount')}}
          </div>
          <div class="points__final-value">
            {{ form.sum ? numberWithSpacesFix2(form.sum): 0  }} 
            {{ $t('currency.currency_symbol') }}
          </div>
          <ButtonElement
            :text="$t('button_props.withdraw')"
            :disabled="isButtonDisabled"
            @click-button="transferAmount"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ButtonElement from "@/components/elements/ButtonElement";
  // import formValidate from "@/mixin/formValidate";
  import numberWithSpaces from '@/mixin/numberWithSpaces'
  import numberWithSpacesFix2 from '@/mixin/numberWithSpaces'
  import formValidate from "@/mixin/formValidate";
  import axios from 'axios';
  
  
  export default {
    name: 'Points',
    components: {
      ButtonElement,
    },
    mixins: [numberWithSpaces, numberWithSpacesFix2, formValidate],
    data() {
      return {
        locale: process.env.VUE_APP_LOCALE,
        selectedPayType: 'account',
        selectedAccounts: null,
        search:'',
        minPayment: 5000,
        // deletedAccounts: null,
        showOptions: false,
        form: {
          bic: '',
          iban: '',
          bank_address: '',
          bank_name: '',
          sum: '',
        },
        errors: {
          bic: '',
          iban: '',
          bank_address: '',
          bank_name: '',
          sum: '',
          business_account: ''
        },
        loading: false
      }
    },
    computed: {
      activeAccount() {
        return this.$store.state.Auth.activeAccount
      },
      userData() {
        return this.$store.state.Auth.user
      },
      businessAccounts() {
        return this.$store.state.Auth.businessAccounts
      },
      filteredAccounts() {
        if (this.search && this.clients) {
          return this.clients.filter( el => 
            (el.name && el.name.toLowerCase().includes(this.search.toLowerCase()))
          )
        } else if (this.clients) {
          return this.clients
        }
        return []
      },
      clients() {
        return this.businessAccounts.reduce((acc, item)=>{
            acc.push({
                      id: item.id,
                      name: item.first_name,
                      lastName: item.last_name,
                      email: item.email,
                      isLegal: item.is_legal,
                      legalName: item.legal_name,
                    })
            return acc
        },[])
      },
      serverErrors() {
        const errors = this.$store.state.Referrals.errors
        return errors === 'Insufficient funds'? 'Недостаточно средств для совершения операции': errors
      },
      checkErrors() {
        for (let key in this.errors) {
            if (this.errors[key] !== '') {
                return true;
            }
        }
        return false;
      },
      isButtonDisabled() {
        if (!this.form || !this.form.sum || !this.userData?.agent) {
          return true;
        }
        return !(this.form.sum >= this.minPayment && this.userData.agent.balance >= this.minPayment && this.userData.agent.balance >= this.form.sum);
      }
    },
    watch: {
      form: {
        deep: true,
        handler () {
          this.$store.commit('Referrals/setData', { label: 'errors', data: null })
          for (const i in this.errors) {
            this.errors[i] = '';
          }
        }
      },
      selectedAccounts: {
        deep: true,
        handler () {
          this.$store.commit('Referrals/setData', { label: 'errors', data: null })
          for (const i in this.errors) {
            this.errors[i] = '';
          }
        }
      },
      selectedPayType(data) {
        if(data) {
          this.$store.commit('Referrals/setData', { label: 'errors', data: null })
          for (const i in this.errors) {
            this.errors[i] = '';
          }
        }
      
      }
    },
    mounted() {
      this.form.bic = this.userData.agent.bic || '';
      this.form.bank_name = this.userData.agent.bank_name || '';
      this.form.iban = this.userData.agent.iban || '';
      this.form.bank_address = this.userData.agent.bank_address || '';
    },
    methods: {
       getPic(data) {
        let result = ''
        if(this.locale==='RU') {
          result = require(`@/assets/icons/${data[0]}`)
        } else {
          result = result = require(`@/assets/icons/${data[1]}`)
        }
        return result
      },
      validateForm () {
        if (!this.form.bank_name && this.selectedPayType === 'account') {
          this.errors.bank_name = this.$t('business_account_data.input_bank')
        }
        if (!this.form.bic && this.selectedPayType === 'account') {
          this.errors.bic = this.$t('business_account_data.input_bank_id')
        }
        if (!this.form.iban && this.selectedPayType === 'account') {
          this.errors.iban = this.$t('business_account_data.input_account_number')
        }
        if (!this.form.bank_address && this.selectedPayType === 'account') {
          this.errors.bank_address = this.$t('business_account_data.input_bank_address')
        }
        if (!this.form.sum) {
          this.errors.sum = this.$t('business_account_data.input_bank_address')
        }
        if(this.selectedPayType ==='business_account' && !this.selectedAccounts) {
          this.errors.business_account = this.$t('points.select_account')
        }
      },
      transferAmount() {
        this.validateForm()
        if (this.checkErrors) {
          return false
        }
        const clientToken = localStorage.getItem('client_token')
        if(this.selectedPayType === 'account') {
          const data = {
            sum: this.form.sum, 
            agent_id: this.userData.agent.id,
            bic: this.form.bic,
            iban: this.form.iban,
            bank_name: this.form.bank_name,
            bank_address: this.form.bank_address
          }
          this.loading = true
          this.$store.dispatch('Referrals/transferToTheAccount', {data, token: clientToken})
          .then(() => {
              axios({ url: process.env.VUE_APP_API_URL + '/auth/userinfo', method: 'GET', headers: { 'Authorization': `Bearer ${clientToken}`} })
              .then(resp => {
                this.$store.commit('Auth/setData', { label: 'user', data: resp.data.data }) 
                this.$store.dispatch('Dialog/closeDialog')
              })
              this.$notify({
                message: this.$t('prepaid.notify_messages.0'),
                type: 'success'
              });
          })
          .catch((err) => {
            this.$notify({
              message: err.response.data.errors === 'Insufficient funds'? this.$t('points.insufficient_funds'): err.response.data.errors,
                type: 'error'
              });
          })
          .finally(() => {
            this.loading = false
          })
        }
        if(this.selectedPayType === 'business_account') {
          const data = {
            agent_id: this.userData.agent.id,
            sum: this.form.sum, 
            ba_id: this.selectedAccounts[0].id
          }
          this.$store.dispatch('Referrals/transferToTheBusinessAccount', {data, token: clientToken})
          .then(() => {
              this.$store.dispatch('Dialog/closeDialog')
              this.$notify({
                message: this.$t('prepaid.notify_messages.0'),
                type: 'success'
              });
          })
          .catch((err) => {
            this.$notify({
              message: err.response.data.errors === 'Insufficient funds'? this.$t('points.insufficient_funds'): err.response.data.errors,
                type: 'error'
              });
          })
          .finally(() => {
            this.loading = false
          })
        }
      },
      chooseAccount(name, id, email, lastName) {
        // if (this.deletedAccounts) {
        //   const index = this.deletedAccounts.findIndex(el => el === id)
        //   if (index !== -1) {
        //     this.deletedAccounts.splice(index, 1)
        //   }
        // }
        // if (!this.selectedAccounts) { 
        //   this.selectedAccounts = [{ name, id, email, lastName }] 
        // } else {
        //   const index = this.selectedAccounts.findIndex(el => el.id === id)
        //   if (index === -1) {
        //     this.selectedAccounts = [{ name, id, email, lastName }]
        //   } else {
        //     this.dellAcc(id)
        //   }
        // }
        this.selectedAccounts = [{ name, id, email, lastName }] 
        this.showOptions = !this.showOptions
      },
      checkStatus(id) {
        if(this.selectedAccounts) {
          const checked = this.selectedAccounts.findIndex(el => el.id === id)
          return checked === -1 ? false : true
        }
      },
      // dellAcc(id) {
      //   if (!this.deletedAccounts) { 
      //     this.deletedAccounts = [id] 
      //   } else {
      //     this.deletedAccounts.push(id) 
      //   }
      //   const index = this.selectedAccounts.findIndex(el => el.id === id)
      //   if (index !== -1) {
      //     this.selectedAccounts.splice(index, 1)
      //   }
      // },
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";

  .selector {
    &__options {
      &-item {
        display: flex;
        gap: 1rem;
      }
    }
  }
  
  .form-input__error {
    padding: 0 0.75rem;
  }
  .note {
    font-size: 12px;
  }
  .dialog-add-account__form-col.selector-section {
    width: 92%;
    .selector__content {
      min-height: 50px;
    }
  }

  .selector__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .selector__title {
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    row-gap : 1rem;
    span {
      font-weight: 700;
      color: #000;
    }
  }
  .selector__options {
    max-height: 40vh;
    overflow-y: auto;
  }
  .points {
    &__available {
        color:  #333;font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &__available-amount {
        color:  #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Roboto';
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
    }
    &__flex-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4.4rem;
    }
    &__errors {
      padding: 1rem 6rem;
      .error {
        font-size: 1.6rem;
        color: #b73339; 
      }
    }
  }

  
  .dialog-add-account__form-type--radio {
    margin-bottom: 2rem;
  }

  .points {
    background: #fff;
    color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    &__total-amount {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #7f858d;
    }
  
    &__cource {
      font-size: 12px;
    }
    &__title {
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.7rem;
      margin-bottom: 4.2rem;
      color: #000;
    }
  
    &__info {
      margin-bottom: 3.2rem;
  
      &-item {
        padding: 1.6rem 0;
        color: #7F858D;
        font-weight: 400;
        & + .points__info-item {
          border-top: 1px solid rgba(0, 0, 0, 0.05);
        }
  
        span {
          display: block;
          max-width: 57rem;
        }
  
        a {
          font-weight: 700;
          color: var(--primary);
        }
      }
    }
  
    &__content {
      padding: 7rem 6rem;
    }
  
    &__final {
      width: 100%;
      background: #EFF1F4;
      padding: 3.3rem 6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      &-title {
        white-space: nowrap;
        font-size: 1.8rem;
        line-height: 2rem;
        color: #333;
        margin-right: 2rem;
      }
  
      &-value {
        font-weight: bold;
        font-size: 3.5rem;
        line-height: 4.1rem;
        margin-right: 2rem;
        white-space: nowrap;
      }
  
      &-block {
        width: 100%;
        padding-right: 4rem;
      }
  
      &-sum, &-nds, &-total {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
  
        &__title {
          font-size: 1.8rem;
          font-weight: 400;
          line-height: 2.2rem;
          color: $grey1;
          margin-right: 1rem;
        }
  
        &__value {
          font-size: 1.8rem;
          font-weight: 700;
          line-height: 2.1rem;
          color: $basicBlack;
        }
      }
  
      &-total {
        margin-top: 3.6rem;
  
        &__title {
          color: $basicBlack;
          margin-right: 2rem;
        }
  
        &__value {
          font-size: 3rem;
          line-height: 3.5rem;
        }
      }
  
      .button {
        width: 27rem;
        height: 4.5rem;
      }
    }
  
    &__step {
      margin-bottom: 6rem;
  
      &:last-of-type {
        margin-bottom: 0;
      }
  
      &-title {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 2.4rem;
        color: #333333;
        margin-bottom: 2rem;
      }
  
      &-content {
        &--three {
          width: 45rem;
        }
      }
  
      &-error {
        color: $redLight;
        position: absolute;
        left: 6rem;
      }
  
      &-tip {
        margin-top: 3rem;
        color: $gray3;
        word-break: normal;
  
        &.dark {
          color: $gray;
        }
      }
  
      &-account-selector {
        width: 40rem;
        margin-bottom: 0.6rem;
        position: relative;
  
        &-value {
          border: 1px solid #BFC2C6;
          border-radius: 2px;
          height: 5rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 5rem 0 1.5rem;
          cursor: pointer;
        }
  
        &-name {
          display: flex;
          align-items: center;
          font-weight: 700;
        }
  
        &-options {
          position: absolute;
          border: 1px solid #BFC2C6;
          border-radius: 2px;
          background: #fff;
          z-index: 10;
          width: 100%;
          left: 0;
          top: calc(100% + 5px);
        }
  
        &-item {
          padding: 0.7rem 1.5rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: 0.3s ease-out;
          font-size: 1.4rem;
  
          span:first-of-type {
            font-weight: 700;
          }
  
          &:hover {
            color: var(--primary);
          }
        }
      }
  
      &-amount {
        position: relative;
        width: 38.7rem;
  
        &-input {
          background: #FFFFFF;
          border: 1px solid #BFC2C6;
          box-sizing: border-box;
          border-radius: 2px;
          height: 5rem;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #333;
          padding: 0 2.4rem 0 1.4rem;
          width: 100%;
        }
  
        &-currency {
          position: absolute;
          right: 1.4rem;
          top: 1.6rem;
          color: #7F858D;
          font-size: 1.6rem;
          line-height: 1.9rem;
        }
      }
    }
  
    &__options {
      display: flex;
  
      &-item {
        position: relative;
        background: #FFFFFF;
        border-radius: 6px;
        border: 2px solid #fff;
        box-shadow: 0 3px 10px rgba(25, 47, 79, 0.12);
        // width: 24rem;
        height: 9.2rem;
        display: flex;
        align-items: center;
        padding: 0 1.8rem;
        margin-right: 2.5rem;
        transition: 0.3s ease-out;
        cursor: pointer;
  
        &.disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
  
        &::before {
          content: '';
          display: block;
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 50%;
          background: url("../../assets/icons/icon-done.svg") var(--primary) no-repeat center;
          background-size: 80%;
          position: absolute;
          top: 0.7rem;
          right: 0.7rem;
          opacity: 0;
        }
  
        &--active {
          box-shadow: none;
          border: 2px solid var(--primary);
  
          &::before {
            opacity: 1;
          }
        }
  
        &:last-of-type {
          margin-right: 0;
        }
  
        input {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
  
      &-icon {
        width: 3.3rem;
        margin-right: 1.4rem;
      }
  
      &-info {
        display: flex;
        flex-direction: column;
      }
  
      &-title {
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
  
      &-value {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.3rem;
        margin-top: 0.7rem;
      }
    }
  }
  
  @include below(993px) {
    .points__title{
      font-size: 22px;
      line-height: 1.3;
      margin-bottom: 30px;
    }
    .points__step{
      margin-bottom: 30px;
    }
    .points__final,
    .points__content{
      padding: 15px;
    }
    .points__step-content--three{
      max-width: 100%;
    }
  }
  
  @include below(769px) {
    .points {
      &__final {
        flex-wrap: wrap;
        .button{
          width: 100%;
          margin-top: 10px;
        } 
      }
      &__final-content{
        flex-direction: column;
      }
    }
    .points__final-value{
      font-size: 24px;
    }
  }

  @include below(577px) {
    .form-input__error {
    padding: 0 2rem;
  }
  }
  
  @include below(500px) {
    .points__options{
      flex-wrap: wrap;
    }
    .points__options-item{
      margin-right: 0;
      width: 100%;
      margin-bottom: 10px;
    }
  
    .points__final-block{
      width: 100%;
      padding-right: 0;
    }
  }
  </style>
  